<template>
  <b-modal
    id="summary"
    title="Engagement Summary"
    size="lg"
    ok-title="Close"
    ok-variant="secondary"
    ok-only
    @hidden="$emit('hidden', true)"
  >
    <h6 class="mb-5">
      <strong>{{ selectedTopic.name }}</strong>
    </h6>
    <b-card border-variant="secondary" class="mb-3">
      <b-card-text>
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-row>
                  <b-col md="6" sm="12">
                    <b-form-group label="From">
                      <el-date-picker
                        :editable="false"
                        :clearable="false"
                        class="w-50"
                        v-model="form.sinceDate"
                        type="date"
                        align="left"
                        size="small"
                        placeholder="From Date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>

                      <el-time-select
                        class="w-50"
                        v-model="form.sinceTime"
                        size="small"
                        :picker-options="{
                          start: '00:00',
                          step: '00:60',
                          end: '23:00'
                        }"
                        placeholder="Select time"
                      >
                      </el-time-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" sm="12">
                    <b-form-group label="To">
                      <el-date-picker
                        :editable="false"
                        :clearable="false"
                        class="w-50"
                        v-model="form.untilDate"
                        type="date"
                        align="left"
                        size="small"
                        placeholder="To Date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>

                      <el-time-select
                        class="w-50"
                        v-model="form.untilTime"
                        size="small"
                        :picker-options="{
                          start: '00:00',
                          step: '00:60',
                          end: '23:00'
                        }"
                        placeholder="Select time"
                      >
                      </el-time-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-form-group>
              <b-form-checkbox inline v-model="isFirst">
                First Message
              </b-form-checkbox>
              <!-- <b-form-checkbox inline v-model="isSum">
                Need Sum
              </b-form-checkbox> -->
            </b-form-group>

            <b-form-group>
              <label for="comment">Comment Summary</label>
              <b-form-textarea
                id="comment"
                v-model="comment"
                placeholder="Enter something..."
                rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group label="Sentiment">
              <b-form-checkbox inline v-model="isShowSentiment">
                Show Sentiment
              </b-form-checkbox>
             <div class="mt-4" v-if="isShowSentiment">
              <div >
                <label>Neutral</label>
                <b-row>
                  <b-col>
                    <b-form-group label="DOM" label-for="neutral_dom">
                      <b-input
                        type="number"
                        id="neutral_dom"
                        min="0"
                        v-model="sentiment.neutral_dom"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Manual" label-for="neutral_manual">
                      <b-input
                        type="number"
                        id="neutral_manual"
                        min="0"
                        v-model="sentiment.neutral_manual"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div>
                <label>Negative to <b>KBANK</b></label>
                <b-row>
                  <b-col>
                    <b-form-group label="DOM" label-for="negative_kbank_dom">
                      <b-input
                        type="number"
                        id="negative_kbank_dom"
                        min="0"
                        v-model="sentiment.negative_kbank_dom"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Manual"
                      label-for="negative_kbank_manual"
                    >
                      <b-input
                        type="number"
                        id="negative_kbank_manual"
                        min="0"
                        v-model="sentiment.negative_kbank_manual"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div>
                <label>Negative to <b>BANK</b></label>
                <b-row>
                  <b-col>
                    <b-form-group label="DOM" label-for="negative_bank_dom">
                      <b-input
                        type="number"
                        id="negative_bank_dom"
                        min="0"
                        v-model="sentiment.negative_bank_dom"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Manual"
                      label-for="negative_bank_manual"
                    >
                      <b-input
                        type="number"
                        id="negative_bank_manual"
                        min="0"
                        v-model="sentiment.negative_bank_manual"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div>
                <label>Negative (Others)</label>
                <b-row>
                  <b-col>
                    <b-form-group label="DOM" label-for="negative_other_dom">
                      <b-input
                        type="number"
                        id="negative_other_dom"
                        min="0"
                        v-model="sentiment.negative_other_dom"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Manual"
                      label-for="negative_other_manual"
                    >
                      <b-input
                        type="number"
                        id="negative_other_manual"
                        min="0"
                        v-model="sentiment.negative_other_manual"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div>
                <label>Positive to <b>KBANK</b></label>
                <b-row>
                  <b-col>
                    <b-form-group label="DOM" label-for="positive_kbank_dom">
                      <b-input
                        type="number"
                        id="positive_kbank_dom"
                        min="0"
                        v-model="sentiment.positive_kbank_dom"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Manual"
                      label-for="positive_kbank_manual"
                    >
                      <b-input
                        type="number"
                        id="positive_kbank_manual"
                        min="0"
                        v-model="sentiment.positive_kbank_manual"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div>
                <label>Positive to <b>BANK</b></label>
                <b-row>
                  <b-col>
                    <b-form-group label="DOM" label-for="positive_bank_dom">
                      <b-input
                        type="number"
                        id="positive_bank_dom"
                        min="0"
                        v-model="sentiment.positive_bank_dom"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Manual"
                      label-for="positive_bank_manual"
                    >
                      <b-input
                        type="number"
                        id="positive_bank_manual"
                        min="0"
                        v-model="sentiment.positive_bank_manual"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div>
                <label>Positive (Others)</label>
                <b-row>
                  <b-col>
                    <b-form-group label="DOM" label-for="positive_other_dom">
                      <b-input
                        type="number"
                        id="positive_other_dom"
                        min="0"
                        v-model="sentiment.positive_other_dom"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Manual"
                      label-for="positive_other_manual"
                    >
                      <b-input
                        type="number"
                        id="positive_other_manual"
                        min="0"
                        v-model="sentiment.positive_other_manual"
                      ></b-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
             </div>
            </b-form-group>

             <b-form-group label="Show Top Most Active By Date">
              <b-form-checkbox inline v-model="isShowTopMostActiveByRange">
                Show Top Most Active By Date Range
              </b-form-checkbox>
              </b-form-group>

            <div class="text-right">
              <b-button
                variant="info"
                @click="saveSummary"
                class="mr-2"
                :disabled="isSaveSummary"
                ><b-spinner small class="mr-1" v-if="isSaveSummary"></b-spinner
                >Save</b-button
              >
              <b-button variant="primary" @click="preview" :disabled="isPreview"
                ><b-spinner small class="mr-1" v-if="isPreview"></b-spinner
                >Preview</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-card-text></b-card
    >

    <b-row>
      <b-col>
        <b-card
          border-variant="secondary"
          header="Summary Log"
          header-tag="h6"
          header-border-variant="secondary"
        >
          <b-card-text>
            <b-row class="mb-3">
              <b-col sm="3">
                <b-form-select
                  size="sm"
                  v-model="paging.perPage"
                  :options="[10, 25, 50, 100]"
                >
                </b-form-select>
              </b-col>
            </b-row>

            <b-table
              bordered
              responsive
              :items="summaries"
              :fields="fields"
              show-empty
              :busy="!isLoaded"
            >
              <template v-slot:table-busy>
                <div class="text-danger my-2">
                  <b-spinner class="align-middle mx-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:head(created_at)="data">
                <div style="width:120px">{{ data.label }}</div>
              </template>

              <template v-slot:head(fromDate)="data">
                <div style="width:120px">{{ data.label }}</div>
              </template>

              <template v-slot:head(toDate)="data">
                <div style="width:120px">{{ data.label }}</div>
              </template>

              <template v-slot:head(updated_at)="data">
                <div style="width:120px">{{ data.label }}</div>
              </template>

              <template v-slot:head(action)="data">
                <div style="width:120px">{{ data.label }}</div>
              </template>

              <template v-slot:empty>
                <center>No data</center>
              </template>

              <template v-slot:cell(no)="row">
                {{ iteration + row.index + 1 }}
              </template>

              <template v-slot:cell(mode)="row">
                {{ row.item.mode === "" ? "Sent" : row.item.mode | capitalize }}
              </template>

              <template v-slot:cell(actions)="row">
                <button-view @click="showSummary(row.item)"></button-view>
                <button-delete
                  v-b-modal.delete-summary
                  @click="selectedSummary = row.item"
                >
                  <i class="flaticon2-trash icon-md"></i>
                </button-delete>
              </template>
            </b-table>
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <span v-if="paging"
                >{{ paging.totalRows | number_format }} records</span
              >
              <b-pagination
                v-if="paging.numberOfPages"
                v-model="paging.currentPage"
                :total-rows="paging.totalRows"
                align="right"
                :number-of-pages="paging.numberOfPages"
                :per-page="paging.perPage"
                use-router
                @change="changeSummary"
              ></b-pagination>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <confirm-delete
      modal-id="delete-summary"
      :remove-fn="removeSummary"
      @ok="changeSummary(1)"
    ></confirm-delete>
  </b-modal>
</template>

<script>
import moment from "moment"
import { SummaryService } from "@/core/services"
export default {
  name: "Summary",
  components: {},
  props: {
    selectedTopic: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        sinceDate: null,
        untilDate: null,
        sinceTime: null,
        untilTime: null
      },

      sentiment: {
        neutral_dom: 0,
        neutral_manual: 0,

        positive_kbank_dom: 0,
        positive_kbank_manual: 0,
        positive_bank_dom: 0,
        positive_bank_manual: 0,
        positive_other_dom: 0,
        positive_other_manual: 0,

        negative_kbank_dom: 0,
        negative_kbank_manual: 0,
        negative_bank_dom: 0,
        negative_bank_manual: 0,
        negative_other_dom: 0,
        negative_other_manual: 0
      },

      isShowSentiment: false,
      isShowTopMostActiveByRange:false,
      isSaveSummary: false,
      isPreview: false,
      isSum: false,
      isFirst: false,
      comment: "",
      dateRange: [
        moment()
          .subtract(1, "hours")
          .startOf("hour")
          .toDate(),
        moment()
          .startOf("hour")
          .toDate()
      ],
      summaries: [],
      isLoaded: false,
      paging: {
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        numberOfPages: 0
      },
      fields: [
        {
          key: "no",
          label: "#",
          class: "text-center"
        },
        {
          key: "mode",
          label: "Mode"
        },
        {
          key: "fromDate",
          label: "From Time"
        },
        {
          key: "toDate",
          label: "To Time"
        },
        {
          key: "created_at",
          label: "Created At",
          thStyle: {
            width: "100px"
          }
        },
        {
          key: "actions",
          label: "Action",
          class: "text-center"
        }
      ],
      lastSummary: null,
      selectedSummary: null
    }
  },
  computed: {
    iteration() {
      return this.paging.perPage * (this.paging.currentPage - 1)
    }
  },
  mounted() {
    this.getSummary()
    this.getLastSentSummary()
    this.getLastSummary()
  },
  methods: {
    preview() {
      this.isPreview = true
      let data = {
        topicId: this.selectedTopic.topicId,
        isSum: this.isSum,
        isFirst: this.isFirst,
        comment: this.comment,
        from: moment(
          `${this.form.sinceDate} ${this.form.sinceTime}`,
          "YYYY-MM-DD HH:mm"
        )
          .startOf("hour")
          .valueOf(),
        to: moment(
          `${this.form.untilDate} ${this.form.untilTime}`,
          "YYYY-MM-DD HH:mm"
        )
          .startOf("hour")
          .valueOf(),
        mode: "preview",
        previousSummaryId: this.lastSummary ? this.lastSummary.summaryId : null,
        sentiment: this.sentiment,
        isShowSentiment: this.isShowSentiment,
        isShowTopMostActiveByRange:this.isShowTopMostActiveByRange
      }

      SummaryService.create(data).then(response => {
        if (response.summaryId) {
          let url = `/#/summary-preview?summaryId=${response.summaryId}${
            this.lastSummary
              ? "&previousSummaryId=" + this.lastSummary.summaryId
              : ""
          }`
          window.open(url, "_blank")
          this.changeSummary(1)
        } else {
          this.$root.$bvToast.toast("Preview Summary Fail", {
            title: `Error`,
            variant: "error",
            solid: true
          })
        }
        this.isPreview = false
      })
    },

    saveSummary() {
      this.isSaveSummary = true
      let data = {
        topicId: this.selectedTopic.topicId,
        isSum: this.isSum,
        isFirst: this.isFirst,
        comment: this.comment,
        from: moment(
          `${this.form.sinceDate} ${this.form.sinceTime}`,
          "YYYY-MM-DD HH:mm"
        )
          .startOf("hour")
          .valueOf(),
        to: moment(
          `${this.form.untilDate} ${this.form.untilTime}`,
          "YYYY-MM-DD HH:mm"
        )
          .startOf("hour")
          .valueOf(),
        mode: "save",
        previousSummaryId: this.lastSummary ? this.lastSummary.summaryId : null,
        sentiment: this.sentiment,
        isShowSentiment: this.isShowSentiment,
        isShowTopMostActiveByRange:this.isShowTopMostActiveByRange
      }

      SummaryService.create(data).then(response => {
        if (response.summaryId) {
          this.$root.$bvToast.toast("Save Summary Success", {
            title: `Success`,
            variant: "success",
            solid: true
          })
        } else {
          this.$root.$bvToast.toast("Preview Summary Fail", {
            title: `Error`,
            variant: "error",
            solid: true
          })
        }
        this.isSaveSummary = false
      })
    },

    removeSummary() {
      return SummaryService.delete(this.selectedSummary.summaryId)
    },

    changeSummary(page) {
      this.paging.currentPage = page
      this.getSummary()
      this.getLastSentSummary()
      this.getLastSummary()
    },
    getSummary() {
      this.isLoaded = false
      return SummaryService.get({
        condition: {
          mode: { $eq: "" },
          topicId: this.selectedTopic.topicId
        },
        offset: this.paging.perPage * (this.paging.currentPage - 1),
        limit: this.paging.perPage
      }).then(summaries => {
        this.paging.totalRows = summaries.total
        this.paging.numberOfPages = Math.ceil(
          this.paging.totalRows / this.paging.perPage
        )
        let datas = summaries.results || []
        this.summaries = datas.map(v => {
          return {
            ...v,
            fromDate: moment(v.fromDate).format("DD/MM/YYYY HH:mm"),
            toDate: moment(v.toDate).format("DD/MM/YYYY HH:mm"),
            created_at: moment(v.created_at).format("DD/MM/YYYY HH:mm"),
            updated_at: moment(v.updated_at).format("DD/MM/YYYY HH:mm")
          }
        })
        this.isLoaded = true
      })
    },
    getLastSummary() {
      return SummaryService.get({
        condition: {
          mode: { $ne: "preview" },
          topicId: this.selectedTopic.topicId
        },
        offset: 0,
        limit: 1
      }).then(summaries => {
        let datas = summaries.results || []
        if (datas[0]) {
          let lastSummary = datas[0]
          this.comment = lastSummary.comment
          this.sentiment = lastSummary.sentiment
            ? lastSummary.sentiment
            : this.sentiment
        }
      })
    },
    getLastSentSummary() {
      return SummaryService.get({
        condition: {
          mode: { $eq: "" },
          topicId: this.selectedTopic.topicId
        },
        offset: 0,
        limit: 1
      }).then(summaries => {
        let datas = summaries.results || []
        if (datas[0]) {
          this.lastSummary = datas[0]

          this.form = {
            sinceDate: moment(this.lastSummary.toDate).format("YYYY-MM-DD"),
            sinceTime: moment(this.lastSummary.toDate).format("HH:mm"),
            untilDate: moment().format("YYYY-MM-DD"),
            untilTime: moment()
              .startOf("hour")
              .format("HH:mm")
          }

          this.sentiment = this.lastSummary.sentiment
            ? this.lastSummary.sentiment
            : this.sentiment
        } else {
          this.form = {
            sinceDate: moment().format("YYYY-MM-DD"),
            sinceTime: moment()
              .startOf("hour")
              .format("HH:mm"),
            untilDate: moment().format("YYYY-MM-DD"),
            untilTime: moment()
              .startOf("hour")
              .format("HH:mm")
          }

          this.sentiment = this.sentiment
        }
      })
    },
    showSummary(summary) {
      if (summary.summaryId) {
        let url = `/#/summary-preview?summaryId=${summary.summaryId}${
          summary.previousSummaryId
            ? "&previousSummaryId=" + summary.previousSummaryId
            : ""
        }`
        window.open(url, "_blank")
      }
    }
  }
}
</script>

<style></style>
